import React from 'react';

const Map = () => {
  const handleClick = () => {
    const latitude = 37.33233141;
    const longitude = -122.0312186;
    const name = 'Apple Park';
    const url = `http://maps.apple.com/?ll=${latitude},${longitude}&q=${name}`;
    window.open(url, '_blank');
  };

  return (
    <button onClick={handleClick}>
      Open Apple Maps
    </button>
  );
};

export default Map;

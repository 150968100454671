import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { Navbar } from 'react-bootstrap';
import Navigation from './components/Navigation';
import Home from './components/Home';
import SignUp from './components/SignUp';
import QRScan from "./components/QRScan";
import Map from "./components/Map";
import { Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import MyToast from "./components/MyToast";
//import { usePosition } from './services/use-position';
const branding = { "logo": "https://intu-qrcodes.s3.us-west-2.amazonaws.com/logos/yetibles.png", "name": "Yetibles"};
function App() {
  // const watch = false;
  // const {
  //   latitude,
  //   longitude,
  //   speed,
  //   timestamp,
  //   accuracy,

  //   heading,
  //   error,
  // } = usePosition(watch);
  
  const [cookies, setCookie] = useCookies(['user']);
  const onSignUp = (user) => {
    setCookie('user', user, { path: '/' });
  }
  const props = { "user": cookies.user, "branding": branding };
  return (
    <div className="App">
      <MyToast />
      <Navigation props={props} />
      <Routes>
        <Route path="/" element={<Home props={props} />} />
        <Route path="/home" element={<Home props={props} />} />
        <Route path="/enroll" element={<SignUp onSignUp={onSignUp}/>} />
        <Route path="/qrscan" element={<QRScan props={props} />} />
        <Route path="/map" element={<Map />} />
      </Routes>
      {/* <code>
        latitude: {latitude}<br />
        longitude: {longitude}<br />
        speed: {speed}<br />
        timestamp: {timestamp}<br />
        accuracy: {accuracy && `${accuracy} meters`}<br />
        heading: {heading && `${heading} degrees`}<br />
        error: {error}
      </code> */}
      <Navbar className="justify-content-center" fixed="bottom" bg="dark" variant="dark" expand="lg">
        <Navbar.Brand>&#169; 2022 IntuRewards.Com</Navbar.Brand>
      </Navbar>
    </div>
  );
}

export default App;

import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
function parseQueryString(queryString) {
    const urlParams = new URLSearchParams(queryString);
    const params = {};
    for (const [key, value] of urlParams) {
        params[key] = value;
    }
    return params;
}
function Claimed(product) {
    return <div><p class="h4">
        Sorry, you scanned a claimed token for {product.itemName} {product.packageName}, which is worth <u><em>{product.points}</em></u> rewards {product.points > 1 ? "points " : "point "}
        valued at <u><em>${product.cashValue}</em></u>.  Inside our products you enjoy, we include a special rewards token for you to claim.  You can accumulate points and use them towards
        future purchases or redeem them for prizes. To enjoy these rewards, we'd love for you to in our rewards program.
    </p></div>;
}
function Unclaimed(product) {
    return <div><p class="h4">
        Congratulations, your purchase of {product.itemName} {product.packageName} is worth <u><em>{product.points}</em></u> rewards {product.points > 1 ? "points " : "point "}
        valued at <u><em>${product.cashValue}</em></u>.  As you purchase and enjoy our products, you can accumulate points and use them towards
        future purchases or redeem them for prizes. To enjoy these rewards, we'd love for you to enroll in our rewards program.
    </p></div>;
}
const QRScan = ({ props }) => {
    const [product, setProduct] = useState([]);
    const [claimed, setClaimed] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const params = parseQueryString(window.location.search);
        setProduct(params);
        setClaimed(params.claimed === 'true');
    }, []);
    return (
        <Container className="my-2">
            <Row>
                <Col>
                    <div className='jumbotron'>
                        <h1>Welcome</h1>
                        <Image src={product.itemImage} roundedCircle width='200px' alt='Product Image' />
                        { claimed ? Claimed(product) : Unclaimed(product) }
                        <Button variant="primary" onClick={() => navigate('/enroll')}>Enroll Me Now</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default QRScan;
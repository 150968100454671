import { Navbar, Nav, Container, Image, Badge } from 'react-bootstrap';

const Navigation = ({ props }) => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand>
                    <a href="/home"><Image  src={props.branding.logo} width="48px" alt={props.branding.name} /></a>
                    {'  '}{props.user ? props.user.firstName : "Enroll Now" }
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/home">Home</Nav.Link>
                        { props.user ? null : <Nav.Link href="/enroll">Enroll</Nav.Link> }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;
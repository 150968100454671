import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const Home = ({props}) => {
    const navigate = useNavigate();
    const getTitle = () => {
        if (props.user) {
            if (props.user.access_count === 0) {
                return `Thank you for joining our loyalty program, ${props.user.firstName}!`;
            } else {
                return `We're glad to see you again ${props.user.firstName}.`;
            }
                
        } else {
            return `${props.branding.name} Welcomes You!`;
        }
    }
    return (
        <Container className="my-2">
            <Row>
                <Col>
                    <div className='jumbotron'>
                        <h1>{getTitle()}</h1>
                        <p>
                            We are excited to offer you the opportunity to earn cash value points
                            on all of your product purchases with us. These points can be redeemed
                            for discounts on future purchases, giving you even more value for your money.
                        </p>
                        <p>
                            But that's not all - by signing up for our rewards program, you will also
                            have access to a convenient feature that directs you to the nearest retail
                            outlet where you can purchase and enjoy our products. This means you'll never 
                            have to go far to find what you're looking for, and you can continue to earn 
                            points and save money on all of your purchases.
                        </p>
                        <p>
                            Don't miss out on this opportunity to get the most out of your shopping
                            experience. Sign up for our rewards program today and start earning cash
                            value points that you can use towards future discounts. We can't wait to
                            welcome you to the program!
                        </p>
                        <Button variant="primary" onClick={() => navigate('/enroll') }>Sign Up Now</Button>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}
export default Home;


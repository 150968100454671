import React, { useRef } from 'react';
import { Button, Form, FloatingLabel, Row, Col } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'

const SignUp = ({onSignUp}) => {
    const [consent, setConsent] = React.useState(false);
    const [mobile, setMobile] = React.useState('');
    const [password, setPassword] = React.useState("");
    const [passwordAgain, setPasswordAgain] = React.useState("");
    const [passwordType, setPasswordType] = React.useState("password");
    const initialInputState = { firstName: "", lastName: "", pswd: "", email: "", mobile: "" };
    const [registration, setRegistration] = React.useState(initialInputState);
    const { firstName, lastName, pswd, email, } = registration;
    const handleChange = (e) => {
        e.preventDefault();
        setRegistration({ ...registration, [e.target.name]: e.target.value });
    };
    const handleConsent = () => {
        setConsent(!consent);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let temp = registration;
        Object.assign(temp, { "pswd": password, "mobile": mobile, "access_count": 0 });
        setRegistration(temp);
        onSignUp(registration)
    };
    return (
        <div className="container-sm">
            <Row className="justify-content-center my-2">
                <Col className="my-2">
                    <FloatingLabel label="First Name">
                        <Form.Control
                            type="text"
                            defaultValue={firstName}
                            name="firstName"
                            required
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Col>
                <Col className="my-2">
                    <Form.Group>
                        <FloatingLabel label="Last Name">
                            <Form.Control
                                type="text"
                                defaultValue={lastName}
                                name="lastName"
                                required
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-center my-2" >
                <Col className="my-2" >
                    <FloatingLabel label="Email Address">
                        <Form.Control
                            type="text"
                            defaultValue={email}
                            name="email"
                            required
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </Col>
                <Col className="my-2">
                    <FloatingLabel label="Mobile Number">
                        <PhoneInput
                            className="form-control"
                            name="mobile"
                            country="US"
                            value={mobile}
                            onChange={setMobile}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="justify-content-center my-3">
                <Col className="my-2">
                    <FloatingLabel label="Password">
                        <Form.Control onChange={e => setPassword(e.target.value)} type={passwordType} />
                    </FloatingLabel>
                </Col>
                <Col className="my-2">
                    <FloatingLabel label="Confirm Password">
                        <Form.Control onChange={e => setPasswordAgain(e.target.value)} type={passwordType} />
                    </FloatingLabel>
                </Col>
                <Row className="justify-content-center my-1">
                    <PasswordChecklist
                        rules={["minLength", "specialChar",
                            "number", "capital", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                    />
                </Row>
            </Row>
            <div className="d-flex flex-row mb-3 justify-content-evenly">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox" id="consentCheck"
                        checked={consent}
                        onChange={handleConsent}
                    />
                    <label className="form-check-label text-start" htmlFor="consentCheck">
                        I agree to InTuRewards' <a href="#!">Terms & Conditions and Privacy Policy</a>
                    </label>
                </div>
            </div>
            <div className='d-flex flex-column mb-3 justify-content-evenly'>
                <Button onClick={handleSubmit} variant="primary" type="submit">
                    Enroll Me!
                </Button>
            </div>
        </div>
    )
}
export default SignUp;

